import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { FaHouse, FaSliders } from "react-icons/fa6";
import { IconContext } from "react-icons/lib";
import { RiSearchFill } from "react-icons/ri";
import { GoBellFill } from "react-icons/go";
import { BsPeopleFill } from "react-icons/bs";
import { GiPodium } from "react-icons/gi";

import HiloLogo from "../assets/hilo-logo.png";

import "../styles/Navbar.css";
import AuthContext from "./AuthProvider";
import { getAuthHeader } from "../utils";
import { User } from "../types/User";

interface NavbarProps {
  userInfo?: User | null;
  showNotifs: boolean;
  setShowNotifs: React.Dispatch<React.SetStateAction<boolean>>;
  hasNew: boolean;
  markAllAsRead: () => void;
}

const Navbar = ({ userInfo=null, showNotifs, setShowNotifs, hasNew, markAllAsRead }: NavbarProps) => {
  const {user} = useContext(AuthContext);
  const [currUserInfo, setCurrUserInfo] = useState<User | null>(null);
  const isMobile = (window.screen.width < 600);

  useEffect(() => {
    if (user != null && userInfo == null) {
      axios.get(`/users/${user.user_id}`, getAuthHeader())
      .then((response) => {
        setCurrUserInfo(response.data);
      })
    }
  }, []);

  return (
    <nav className="nav-gradient rounded-t-none sm:rounded-tr-[30px] rounded-b-none flex flex-row sticky top-0 z-50 bg-black">
      <div className="flex flex-row items-center gap-2">
        <Link to="/" className="sm:ml-8 z-100">
          <img src={HiloLogo} className="h-12 sm:h-20" alt="HiLo" />
        </Link>
        {(!isMobile) && <button
          className="text-white bg-[#525252] rounded-md py-1 px-2 hover:bg-[#6f6f6f] flex flex-row gap-1.5 items-center"
          onClick={() => {
            window.open("https://discord.gg/cenrnEdR", "_blank", "noopener,noreferrer");
          }}
        >
          <span className="font-sans">
            Join Discord
          </span>
          <div className="h-5 w-6 rounded-full overflow-hidden">
            <img
              src="https://s3-alpha-sig.figma.com/img/ee54/44d6/daa29446ccf373744370347930dfda44?Expires=1739750400&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=okEDt8YFzKrMcsMrSaK3P6SQFyJgZu7CLM5c1G2PUTaZxnq-NGrsU7RJkGzFwYTMBw-2~e2ONqsAOuzCBcqxkz0tMyY3zAsCj-NOBY9xGAbn8EVhtD8uo61uvdzoiqR~WaoQIecY~kZNsUMWaHeII7oD239IhiVQbQ2EPviwnLQljGU4QJcmHTcfaV7O0lHWXDfY-dKDl0y-vb7KIiyO2C-bzhbu7NwLvEG5BxtQAMkS~oazbH8q0dA0ZDP4KuTEt~yEazeTOrGNFxA3HhYFY1Na8NUSdDbhyfCWYDPlYmru50bIN~Lw-oRHtnA9gO0zF8vbGSm7gKsXlrAfA6amRQ__"
              alt=""
              className="rounded-full w-full h-full"
            />
          </div>
        </button>}
      </div>
      <div className="flex flex-row gap-6 sm:gap-24 ml-auto mr-4 sm:mr-12 items-center">
        <Link to="/">
          <button type="button">
            <IconContext.Provider value={{ color: "white" }}>
              <FaHouse
                className="text-xl sm:text-2xl"
              />
            </IconContext.Provider>
          </button>
        </Link>
        <Link to="/community">
          <button type="button">
            <IconContext.Provider value={{ color: "white" }}>
              <GiPodium
                className="text-xl sm:text-2xl"
              />
            </IconContext.Provider>
          </button>
        </Link>
        <Link to="/friends">
          <button type="button">
            <IconContext.Provider value={{ color: "white" }}>
              <BsPeopleFill
                className="text-xl sm:text-2xl"
              />
            </IconContext.Provider>
          </button>
        </Link>
        {/* <button type="button">
          <IconContext.Provider value={{ color: "white" }}>
            <RiSearchFill
              className="text-xl sm:text-2xl"
            />
          </IconContext.Provider>
        </button> */}
        <button
          type="button"
          className="relative"
          onClick={(e) => {
            e.preventDefault();
            markAllAsRead();
            setShowNotifs(!showNotifs);
          }}
        >
          {hasNew && (
            <div className="h-1 w-1 sm:h-3 sm:w-3 rounded-full bg-teal-200 absolute top-0 right-0 bg-insightGradientStart"></div>
          )}
          <IconContext.Provider value={{ color: "white" }}>
            <GoBellFill className="text-xl sm:text-2xl" />
          </IconContext.Provider>
        </button>
        <button type="button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `/settings`;
          }}
        >
          <IconContext.Provider value={{ color: "white" }}>
            <FaSliders
              className="text-xl sm:text-2xl"
            />
          </IconContext.Provider>
        </button>
        <div className="h-6 w-6 sm:h-12 sm:w-12 overflow-hidden rounded-full hover:cursor-pointer">
          {(userInfo != null && userInfo?.profile_pic?.length > 0) && <img src={userInfo?.profile_pic} alt="" className="w-full h-full object-cover"
            onClick={() => {
              if (userInfo != null && userInfo?.username?.length > 0)
                window.location.href=`/profile/${userInfo?.username}`;
              else 
                alert("You must login/register first.");
            }}
          />}
          {currUserInfo?.profile_pic ? (
            <img
              src={currUserInfo.profile_pic}
              alt=""
              className="w-full h-full object-cover"
              onClick={() => {
                if (currUserInfo.username) {
                  window.location.href = `/profile/${currUserInfo.username}`;
                } else {
                  alert("You must login/register first.");
                }
              }}
            />
          ) : null}
        </div>
      </div>
    </nav>
  )
}

export default Navbar;